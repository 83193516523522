import moment from "moment";
import { GetMonth } from "./getMonth";

export const dataFormat = (data: string) => {
    let format = new Date(data);
    let formatString = format.toLocaleDateString("pt-BR").split("/");
    let text = `${formatString[0]} de ${GetMonth(parseInt(formatString[1]))}, ${formatString[2]}`;

    return text;
}

export const datePlusWeek = (data: string, weeks: number) => {
    const dataInicialStr = data;

    // Converte a string em um objeto Moment.js
    const dataMoment = moment(dataInicialStr, 'YYYY-MM-DD HH:mm:ss');

    // Adiciona o número de semanas definido
    const novaDataMoment = dataMoment.add(weeks, 'weeks');

    // Formata a nova data em uma string legível
    const novaDataFormatada = novaDataMoment.format('YYYY-MM-DD HH:mm:ss');

    return novaDataFormatada;
}

export const dateDiff = (data1Str: string, data2Str: string) => {

    // Converte as strings em objetos Moment.js
    const data1Moment = moment(data1Str, 'YYYY-MM-DD HH:mm:ss');
    const data2Moment = moment(data2Str, 'YYYY-MM-DD HH:mm:ss');

    // Calcula a diferença em dias
    const diferencaDias = data2Moment.diff(data1Moment, 'days');

    return diferencaDias;
}