import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Photos = styled.div` 
    background-color: #333;
    width: 80%;
    height: 80%;
    border-radius: 5px;
    position: relative;

    .close{
        top: 0px;
        right: 0px;
        background-color: #333;
        width: 40px;
        height: 40px;
        position: absolute;
    }
    .img{
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
    }
`;

export const Menu = styled.div` 
    height: 50px;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    .info{
        background-color: #111;
        padding: 5px 8px;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
    }

    button{
        background-color: #111;
        padding: 5px 8px;
        border-radius: 5px;
    }
`;