/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import { AppDispatch } from "app/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProposalThunk } from "app/reducers/proposital/thunk";
import ChipAdd from "components/Chips/ChipAdd";
import InputStyle from "components/Inputs";
import { ContentProfile } from "components/style";
import TextArea from "components/Textarea";
import { IStateData } from "interfaces/components.interface";
import { IProposalPost } from "interfaces/data/demand.interface";
import { useForm } from "util/form/useForm";
import { showErrorMessage } from "util/function";
import { LGPD } from "components/LGPD/LGPD";

interface IProps {
  idDemand?: string;
  setTrigger?: any;
  trigger?: boolean;
  setPrimary: any;
  primaryValue: any;
  setTerms: any;
  termsResponse: boolean;
  setResponse: any;
}

export const RegisterProposal = ({ idDemand, setTrigger, trigger, setPrimary, primaryValue, setTerms, termsResponse, setResponse }: IProps) => {
  const { loading } = useSelector((state: IStateData) => state.proposal);
  const { auth } = useSelector((state: IStateData) => state.auth);
  const [valueFormat, setValuFormat] = useState("");
  const [time, setTime] = useState([]);
  const [description, setDescription] = useState("");
  const [timeReset, setTimeReset] = useState(false);
  const [contact, setContact] = useState("");

  const initialValues: IProposalPost = {
    deadline: "",
    email: "",
    numberInvolved: 0,
    demands_id: idDemand?.toString(),
    description: "",
    time: "",
    value: "0",
  };

  const dispatch = useDispatch<AppDispatch>();
  const { onChange, values } = useForm(initialValues);


  const handleSavedData = async (valuesSave: IProposalPost) => {
    setTimeReset(false);
    dispatch(
      createProposalThunk({
        ...valuesSave,
        value: valueFormat,
        description,
        demands_id: idDemand,
        time: time.join("@"),
        email: contact,
        numberInvolved: time.length,
      }),
    );

    // dispatch(fetchDemandsThunk());

    setTime([]);
    setDescription("");
    setValuFormat("");
    setContact("");

    if (!auth.logged) {
      showErrorMessage("Verifique seu email para completar o envio", "success");
    }else{
      setContact(auth.user[0].email);
    }
    setTimeReset(true);
    values.deadline = "";
  };

  useEffect(() => {
    if (auth.logged) {
      setContact(auth.user[0].email);
    }
  }, [auth])

  useEffect(() => {
    if (!loading && timeReset) {
      setPrimary(false);
    }
  }, [loading, timeReset, setTimeReset, setPrimary])
  

  useEffect(() => {
    if (primaryValue) {
      if (description.length === 0 || time.length === 0 || contact.length === 0 || valueFormat.length === 0 || values.deadline.length === 0) {
        showErrorMessage("Preencha todos os campos", "error");
        setPrimary(false);
        return;
      }

      setTrigger(false);
      setTerms(true);
    }
  }, [primaryValue]);

  useEffect(() => {
    if (primaryValue) {
      if (termsResponse) {
        setTrigger(true);
        handleSavedData(values);
        setTerms(false);
        setResponse(false);
        return;
      }
      if (termsResponse === undefined) {
        setTrigger(true);
        setPrimary(false);
        showErrorMessage("Por favor, aceite os termos de uso", "error");
        setResponse(false);
        return;
      }
    }
  }, [termsResponse]);

  const handlerMoney = (value: any) => {
    var valor = value.replace(/[^\d]+/gi, '').split('').reverse().join('');
    var resultado = "";
    var mascara = "##.###.###,##".split('').reverse().join('');
    for (var x = 0, y = 0; x < mascara.length && y < valor.length;) {
      if (mascara.charAt(x) != '#') {
        resultado += mascara.charAt(x);
        x++;
      } else {
        resultado += valor.charAt(y);
        y++;
        x++;
      }
    }

    setValuFormat(resultado.split('').reverse().join(''));
  }

  return (
    <>
      <ContentProfile>
        <div className="content-default">
          <div className="content-basic-data">
            <h1 className="title-h3">Dados básicos</h1>
            <TextArea
              setState={setDescription}
              value={description}
              required
              height="110px"
              placeholder="Descreva sua proposta..."
              length={30000}
              title=""
              className="form-control-demand text-areax"
            />
            <div className="double-data">
              <InputStyle
                onChange={onChange}
                valueChanges={values.deadline}
                required
                placeholder="Prazo de conclusão (semanas)"
                type="number"
                title="Prazo de conclusão"
                name="deadline"
                className="text-double"
              />
              <InputStyle
                onChange={(e) => handlerMoney(e.target.value)}
                required
                valueChanges={valueFormat}
                placeholder="somente numeros"
                type="text"
                title="Valor do orçamento"
                name="value"
                className="text-double"
              />
            </div>
            <InputStyle
              onChange={(e) => setContact(e.target.value)}
              required
              valueChanges={contact}
              placeholder="E-mail de contato"
              type="email"
              title="E-mail de contato"
              name="email"
              className="text-double"
            />
          </div>
          <div className="content-data-time">
            <h1 className="title-h3">Equipe executora</h1>
            <div className="form-control-demand">
              <ChipAdd setState={setTime} listValue={time} reset={timeReset} text="Adicione o membro da equipe" />
            </div>
          </div>
        </div>
      </ContentProfile>
    </>
  );
}

export default RegisterProposal;
