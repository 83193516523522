import { Container } from "../ProposalList/style";
import { FaCity, FaTimes, FaUsers } from "react-icons/fa";
import { BsFillTrash2Fill, BsCheckLg, BsStopwatch } from "react-icons/bs";
import { AiOutlineArrowLeft, AiOutlineUser, AiTwotoneCalendar } from "react-icons/ai";
import { IDemand, IProposal, ITeam } from "interfaces/data/demand.interface";
import { useEffect, useRef, useState } from "react";
import { GetMonth } from "util/getMonth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { findOneDemands } from "API/Demand/find.demand";
import { bondRequest, updateDeadLine, updateProposal, updateStatus } from "API/Demand/Proposital/crud.proposal";
import { findByDemandProposal, findProposal } from "API/Demand/Proposital/find.proposal";
import { findTeam } from "API/Team/find.team";
import { dataFormat, dateDiff, datePlusWeek } from "util/dateFormater";
import { convertToArray } from "util/handleSelectorObj";
import { BiCheck, BiCheckCircle, BiMailSend } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import TextArea from "components/Textarea";
import { CircleLoading } from "components/circleLoading";
import { apiUrl, url } from "config";
import { formatKeyTypes, showErrorMessage } from "util/function";
import { LoadingDefault } from "components/Loading";
import InputStyle from "components/Inputs";
import { closeDemandReq } from "API/Demand/crud.demand";
import check from '../../assets/img/check.png';
import { IStateData } from "interfaces/components.interface";
import { useNavigate } from "react-router";
import { SelectMenuAlternative } from "components/Select/Alterntive";
import { findAllByUsersThunk } from "app/reducers/demand/thunk";


interface IProposalList {
    state: boolean;
    setState: any;
}

export const ProposalRequest = ({ state, setState }: IProposalList) => {
    const { item } = useSelector((state: IStateData) => state.proposal);
    const { auth } = useSelector((state: IStateData) => state);
    const { demands } = useSelector((state: IStateData) => state);
    const nav = useNavigate();
    const [team, setTeam] = useState([]);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState<any>();
    const [finishDemand, setFinishDemand] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const [description, setDescription] = useState("");
    const [demand, setDemand] = useState("none");


    useEffect(() => {
        if (state) {
            console.log(item);
            if (item !== undefined) {

                if (item.Team !== undefined) {
                    let team: any = item.Team;
                    team = convertToArray(team.Team);
                    setTeam(team);
                }
            }
        } else {
            nav("/propostas");
        }
    }, [state])

    const sendBondRequest = async () => {
        if(description.length === 0 || demand === "none"){
            showErrorMessage("Por fvaor, preencha todos os dados", "error");
            return;
        }

        if(item){
            setLoading(true);
            let req = await bondRequest(description, demand, item?.id);
            setLoading(false);

            if(req.status === 200){
                setDescription("");
                setDemand("");
                setFinishDemand(false);
            }
            showErrorMessage(req.message, (req.status === 200 ? "success" : "error"));
        }
    }

    const handlePropUsername = () => {
        let name = "Publico";

        if (item?.User !== undefined) {
            if (item.User) {
                name = item.User.name;
            }
        }

        return name;
    }

    function gerarCorAleatoria() {
        const letrasHex = '0123456789ABCDEF'; // Define as letras permitidas em hexadecimais
        let cor = '#'; // Começa a cor com o símbolo "#" para indicar que é um valor hexadecimal

        // Gera seis caracteres aleatórios em hexadecimal e adiciona à cor
        for (let i = 0; i < 6; i++) {
            cor += letrasHex[Math.floor(Math.random() * 16)];
        }

        return cor; // Retorna a cor gerada
    }

    function copyEmail(email: string) {
        navigator.clipboard.writeText(email)
            .then(() => {
                setCopy(true);

                setTimeout(() => {
                    setCopy(false);
                }, 2000)
            })
            .catch((error) => {
                console.error('Erro ao copiar texto:', error);
            });
    }

    function handleRequestProposal() {
        if (!auth.auth.logged) {
            showErrorMessage("Você precisa estar logado para executar essa ação", "error");
            return;
        }

        dispatch(findAllByUsersThunk(auth.auth.user[0].id));
        setFinishDemand(true);
    }

    return (
        <Container state={state}>
            <LoadingDefault
                active={loading || demands.loading}
            />
            <div className="body">
                <div className="title">
                    <div className="info">
                        <h1>Detalhes da Propostas</h1>
                        <h3>Dded</h3>
                    </div>
                    <span style={{ cursor: "pointer" }} onClick={() => { setState(false); setDetails(undefined) }}><FaTimes size={25} color="#8f9497" /></span>
                </div>
                <div className="content">
                    {!finishDemand && demands.demand.length > 0 ? (
                        <>
                            <div className="controls">
                                <div className="tag" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)", marginBottom: "12px", paddingBottom: "12px" }}>
                                    <div className="status-2"></div>
                                    <h1>Proposta em aberto&nbsp;&nbsp;&nbsp;</h1>
                                </div>
                                <div className="buttons">
                                    <>
                                        <button onClick={() => handleRequestProposal()}>Solicitar vinculo &nbsp;&nbsp;<div className="icon"><BiCheckCircle size={15} /></div></button>
                                        {/* <button onClick={() => setFinishDemand(true)}>Gerenciar equipe &nbsp;&nbsp;<div className="icon"><FaUsers size={15} /></div></button> */}
                                    </>
                                </div>
                            </div>
                            <div className="infoBox">
                                <h1>Detalhes</h1>
                                <div className="content">
                                    <div className="left">
                                        <div className="item">
                                            <h2>Proponente</h2>
                                            <p>{handlePropUsername()}</p>
                                        </div>
                                        <div className="item">
                                            <h2>Orçamento</h2>
                                            <p>R$ {item && item.Details.value}</p>
                                        </div>
                                        <div className="item">
                                            <h2>E-mail</h2>
                                            <p><a href={`mailto:?subject=Plataforma de governança - Duvida sobre proposta`}>{item && item.email}</a></p>
                                        </div>
                                        <div className="item">
                                            <h2>Prazo de conclusão</h2>
                                            <p>{item && item.Details.deadline + " semanas"}</p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="congratulation">
                                            <img src={check} width="40px" alt="" />
                                            <h2>Em stand by</h2>
                                            <p>Gostaríamos muito de fazer parte da sua demanda e contribuir para a transformação do Oeste do Paraná.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="infoBox">
                                <h1>Plano proposto</h1>
                                <div className="content">
                                    <p className="text">{item && item.description.trim()}</p>
                                </div>
                            </div>
                            <div className="infoBox">
                                <h1>Equipe</h1>
                                <div className="content">
                                    <div className="teamList">
                                        {team.length !== 0 && team.map((item: ITeam, index: any) => (
                                            <div className="item" key={index}>
                                                <div style={{ backgroundColor: gerarCorAleatoria() }} className="icon">
                                                    <AiOutlineUser size={20} color="#fff" />
                                                </div>
                                                <div className="carg">
                                                    <h4>{item.name}</h4>
                                                    <p>Sem função</p>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="feedBack-form">
                            <h1>Solicitar vinculo</h1>
                            <p style={{width:"100%", fontSize: "14px"}}>Selecione e descreva sua demanda abaixo e como nossa proposta pode contribuir para atendê-la.<b> Após a aceitação, sua demanda será marcada como "em execução" e outras propostas vinculadas serão movidas para o banco de propostas. Prossiga com cuidado.</b></p>
                            <div style={{ marginBottom: "15px" }}>
                                <SelectMenuAlternative
                                    value={demand}
                                    setState={setDemand}
                                    name="city_id"
                                    className="text-double text-popup"
                                    options={formatKeyTypes(["Selecione sua demanda", ...demands.demand], {})}
                                />
                            </div>
                            <TextArea
                                setState={setDescription}
                                value={description}
                                required
                                height="100%"
                                placeholder="Escreva aqui..."
                                length={30000}
                                title="Feedback"
                                className="form-control-demand text-areax"
                            />
                        </div>
                    )
                    }
                </div>
                {finishDemand && (
                    <div className="footer">
                        <button className={`accept ${(description.length === 0 || demand === "none") ? "disabled" : ""}`} onClick={() => sendBondRequest()} disabled={description.length === 0 || demand === "none"}>{(false) ? <CircleLoading color="#fff" /> : "Enviar"}</button>
                        <button className="denied" onClick={() => setFinishDemand(false)}>Voltar</button>
                    </div>
                )}
            </div>
        </Container>
    )
}