import { Container } from "./style";
import { FaCity, FaTimes, FaUsers } from "react-icons/fa";
import { BsFillTrash2Fill, BsCheckLg, BsStopwatch } from "react-icons/bs";
import { AiOutlineArrowLeft, AiOutlineUser, AiTwotoneCalendar } from "react-icons/ai";
import { IDemand, IProposal, ITeam } from "interfaces/data/demand.interface";
import { useEffect, useRef, useState } from "react";
import { GetMonth } from "util/getMonth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { findOneDemands } from "API/Demand/find.demand";
import { updateDeadLine, updateProposal, updateStatus } from "API/Demand/Proposital/crud.proposal";
import { findByDemandProposal, findProposal } from "API/Demand/Proposital/find.proposal";
import { findTeam } from "API/Team/find.team";
import { dataFormat, dateDiff, datePlusWeek } from "util/dateFormater";
import { convertToArray } from "util/handleSelectorObj";
import { selectCurentUser } from "app/reducers/auth/authSlice";
import { BiCheck, BiCheckCircle, BiMailSend } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import TextArea from "components/Textarea";
import { CircleLoading } from "components/circleLoading";
import { apiUrl, url } from "config";
import { showErrorMessage } from "util/function";
import { LoadingDefault } from "components/Loading";
import InputStyle from "components/Inputs";
import { closeDemandReq } from "API/Demand/crud.demand";
import check from '../../assets/img/check.png';


interface IProposalList {
    state: boolean;
    setState: any;
    data?: IDemand;
    setData?: any;
    outDetails?: any;
}

export const ProposalList = ({ state, setState, data, outDetails, setData }: IProposalList) => {
    let dayCounter = useRef<any>("");
    const [team, setTeam] = useState([]);
    const [descButton, setDescButton] = useState(true);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>();
    const [demandId, setDemandId] = useState<any>();
    const [details, setDetails] = useState<any>();
    const [finishDemand, setFinishDemand] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const [description, setDescription] = useState("");
    const [increaseDeadline, setIncreaseDeadline] = useState("");
    const [proposalWork, setProposalWork] = useState<IProposal>();

    useEffect(() => {
        if (outDetails !== undefined) {
            openProposalData(outDetails);
        }
    }, [outDetails, details]);

    useEffect(() => {
        if (finishDemand) {
            console.log(description.length);
            if (description.length >= 200) {
                setDescButton(false);
            } else {
                setDescButton(true);
            }
        }
    }, [description, finishDemand]);

    useEffect(() => {
        if (state) {
            if (data !== undefined) {
                if (data.Proposal && proposalWork === undefined) {
                    let proposal = data.Proposal as any;


                    setProposalWork(data.Proposal as IProposal);

                    console.log(proposal.Team);
                    if (proposal.Team !== undefined) {
                        let team: any = convertToArray(proposal.Team.Team);
                        setTeam(team);
                    }
                }
                setDemandId(data.id);
                setList([]);
                console.log(data);
            }
        } else {
            setProposalWork(undefined);
        }
    }, [state, data, proposalWork]);

    useEffect(() => {
        if (list && list.length === 0) {
            const refreshProposal = async () => {
                if (demandId) {
                    let proposal: any = await findByDemandProposal(demandId);

                    if (proposal.data) {
                        setList(convertToArray(proposal.data.ProposalList));
                    }
                }
            }

            refreshProposal();
        }
    }, [list, demandId]);

    const setCurrency = (num: string) => {
        let fnum = parseFloat(num);

        return fnum.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' });
    }

    const setProposalState = async (id: string, state: string) => {
        if (id) {
            // eslint-disable-next-line no-restricted-globals
            let pass = prompt("Ao confirmar essa ação sua demanda se tornará fechada para o aceite de proposta. Digite sua senha no campo abaixo para prosseguir");

            if (pass?.length === 0) {
                showErrorMessage("Digite sua senha", "error");
                return;
            }

            if (pass !== null) {
                setLoading(true);
                const up = await updateStatus({
                    id: id,
                    isAproved: state,
                    password: pass.trim()
                })

                console.log(up);
                setLoading(false);
                if (up.status !== 200) {
                    showErrorMessage(up.message, "error");
                    return;
                }

                let team: any = convertToArray(up.data.Proposal.Team.Team);

                setData(up.data);
                setProposalWork(up.data.Proposal as IProposal);
                setTeam(team);
                console.log(up.data.Proposal);
                showErrorMessage(up.message, "success");
            }
        }
    }

    const openProposalData = async (item: IProposal) => {
        if (item) {
            let data = await findTeam(item.id);

            let fullData: any = Object.assign({}, item);
            fullData['team'] = convertToArray(data);

            if (outDetails !== undefined) {
                fullData['name'] = (item.User) ? item.User.name : "Publico";
            }

            setDetails(fullData);
        }
    }

    const setStatusText = (text: any) => {
        switch (text) {
            case "2": text = "Aberto";
                break;
            case "1": text = "Em execução";
                break;
            default: text = "Finalizado";
        }

        return text;
    }

    const handlePropUsername = () => {
        let name = "Publico";

        if (proposalWork !== undefined) {
            if (proposalWork.User) {
                name = proposalWork.User.name;
            }
        }

        return name;
    }

    const increaseDeadlineFunc = async () => {
        if (increaseDeadline.length === 0) {
            showErrorMessage("Informe um valor em semanas valido", "error");
            return;
        }

        if (proposalWork !== undefined) {
            let req = await updateDeadLine(proposalWork.Details.id, increaseDeadline);
            if (req.status === 200) {
                dayCounter.current.innerHTML = parseInt(dayCounter.current.innerHTML) + (parseInt(increaseDeadline) * 7);
                showErrorMessage("Prazo alterado com sucesso", "success");
                setIncreaseDeadline("");
            }
        }
    }

    const closeDemand = async () => {
        if (!descButton) {
            // eslint-disable-next-line no-restricted-globals
            let pass = prompt("Essa ação é irreversivel ao confirmar sua demanda será fechada. Digite sua senha no campo abaixo para prosseguir");

            if (pass?.length === 0) {
                showErrorMessage("Digite sua senha", "error");
                return;
            }

            if (pass !== null) {
                setLoading(true);
                const up = await closeDemandReq(
                    demandId,
                    description,
                    pass.trim()
                )

                setLoading(false);
                if (up.status !== 200) {
                    showErrorMessage(up.message, "error");
                    return;
                }

                setData(up.data);
                setFinishDemand(false);
                setTeam(up.data.Team.Team);
                showErrorMessage(up.message, "success");
            }
        }
    }

    function gerarCorAleatoria() {
        const letrasHex = '0123456789ABCDEF'; // Define as letras permitidas em hexadecimais
        let cor = '#'; // Começa a cor com o símbolo "#" para indicar que é um valor hexadecimal

        // Gera seis caracteres aleatórios em hexadecimal e adiciona à cor
        for (let i = 0; i < 6; i++) {
            cor += letrasHex[Math.floor(Math.random() * 16)];
        }

        return cor; // Retorna a cor gerada
    }

    function copyEmail(email: string) {
        navigator.clipboard.writeText(email)
            .then(() => {
                setCopy(true);

                setTimeout(()=>{
                    setCopy(false);
                }, 2000)
            })
            .catch((error) => {
                console.error('Erro ao copiar texto:', error);
            });
    }

    return (
        <Container state={state}>
            <LoadingDefault
                active={loading}
            />
            <div className="body">
                <div className="title">
                    <div className="info">
                        <h1>{(outDetails === undefined) ? "Lista de Propostas" : "Detalhes da Propostas"}</h1>
                        <h3>{data?.name}</h3>
                    </div>
                    <span style={{ cursor: "pointer" }} onClick={() => { setState(false); setDetails(undefined) }}><FaTimes size={25} color="#8f9497" /></span>
                </div>
                <div className="content">
                    {details &&
                        (
                            <div className="details">
                                {outDetails === undefined &&
                                    (
                                        <button className="backButton" onClick={() => setDetails(undefined)}><AiOutlineArrowLeft /> Voltar</button>
                                    )
                                }
                                <p className="subTitle">Contato</p>
                                <div className="participants">
                                    <button onClick={() => copyEmail(details.email)}>{details.email} <BiMailSend size={15} /> {copy && <div className="copied">Copiado</div>}</button>
                                </div>
                                <p className="subTitle">Plano proposto</p>
                                <p className="text">{details.description}</p>
                            </div>
                        )
                    }
                    {!details &&
                        (
                            <>
                                {outDetails === undefined && (
                                    <>
                                        {finishDemand ? (
                                            <div className="feedBack-form">
                                                <h1>Finalizar demanda</h1>
                                                <p>Nos conte como foi a experiência de aplicar a demanda em seu município. Seu feedback é imporante para guiar futuros demandantes que venham a utilizar a plataforma.</p>
                                                <TextArea
                                                    setState={setDescription}
                                                    value={description}
                                                    required
                                                    height="100%"
                                                    placeholder="Descreva sua experiência (minimo 200 caracteres)..."
                                                    length={30000}
                                                    title="Feedback"
                                                    className="form-control-demand text-areax"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="controls">
                                                    <div className="tag">
                                                        <div className={(data?.status) ? "status-" + data?.status : ""}></div>
                                                        <h1>{(data && data?.name.length > 30) ? data?.name.toString().substring(0, 30) + '...' : data?.name}&nbsp;&nbsp;&nbsp;<a href={url + `demanda/${data?.id}`} target="_blank" rel="noreferrer"><span>#{data?.id}</span></a></h1>
                                                        <span className={`state ${(data?.status) ? "status-" + data?.status : ""}`}>{setStatusText(data?.status)}</span>
                                                    </div>
                                                    <div className="info" style={{ borderBottom: (data?.status != 1) ? "0" : "inherit", marginBottom: (data?.status != 1) ? "0" : "12px" }}>
                                                        <div className="item">
                                                            <FaCity size={16} />
                                                            &nbsp;
                                                            {data?.Cities.name} - {data?.Cities.uf}
                                                        </div>
                                                        <div className="item">
                                                            <HiOutlineLightBulb size={16} />
                                                            &nbsp;
                                                            {data?.Axes.name}
                                                        </div>
                                                        <div className="item">
                                                            <AiTwotoneCalendar size={16} />
                                                            &nbsp;
                                                            {(data) ? dataFormat(data?.createdAt) : ""}
                                                        </div>
                                                    </div>
                                                    <div className="buttons">
                                                        {data?.status == 1 && (
                                                            <>
                                                                <button onClick={() => setFinishDemand(true)}>Finalizar demanda &nbsp;&nbsp;<div className="icon"><BiCheckCircle size={15} /></div></button>
                                                                {/* <button onClick={() => setFinishDemand(true)}>Gerenciar equipe &nbsp;&nbsp;<div className="icon"><FaUsers size={15} /></div></button> */}
                                                            </>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                                {data && data.status == 2 ? (
                                                    <div className="table">
                                                        <div className="infoBox">
                                                            <h1><span style={{ color: "#ed5371" }}>{(list) ? list.length : 0}</span> Propostas encontradas</h1>
                                                            <div className="content" style={{ padding: 0 }}>
                                                                <table style={{ marginTop: 0 }}>
                                                                    <thead>
                                                                        <th>Proposta</th>
                                                                        <th>Prazo de conclusão (semanas)</th>
                                                                        <th>Orçamento</th>
                                                                        <th>Situação</th>
                                                                        <th>Ações</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {list &&
                                                                            list.map((item: any, index: number) => (
                                                                                <tr key={index}>
                                                                                    <td onClick={() => openProposalData(item)} style={{ paddingLeft: 0 }}><p className="field-button" style={{ textAlign: "center" }}>Ver mais</p></td>
                                                                                    <td style={{ textAlign: "center" }}>{item.Details.deadline}</td>
                                                                                    <td>R$ {item.Details.value}</td>
                                                                                    <td className={(item.isAproved == 1) ? "aproved" : "await"}>{(item.isAproved == 1) ? "Aprovado" : "Pendente"}</td>
                                                                                    <td>
                                                                                        <span className="acceptButton" onClick={() => setProposalState(item.id, "1")}>Aceitar</span>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {proposalWork !== undefined && (
                                                            <>
                                                                <div className="infoBox">
                                                                    <h1>Proposta aceita</h1>
                                                                    <div className="content">
                                                                        <div className="left">
                                                                            <div className="item">
                                                                                <h2>Proponente</h2>
                                                                                <p>{handlePropUsername()}</p>
                                                                            </div>
                                                                            <div className="item">
                                                                                <h2>Orçamento</h2>
                                                                                <p>R$ {proposalWork.Details.value}</p>
                                                                            </div>
                                                                            <div className="item">
                                                                                <h2>E-mail</h2>
                                                                                <p><a href={`mailto:${proposalWork.email}?subject=Plataforma de governança - Duvida sobre proposta`}>{proposalWork.email}</a></p>
                                                                            </div>
                                                                            <div className="item">
                                                                                <h2>Aceito em</h2>
                                                                                <p>{dataFormat(proposalWork.Details.accept_at)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right">
                                                                            {data?.status == 3 ? (
                                                                                <div className="congratulation">
                                                                                    <img src={check} width="40px" alt="" />
                                                                                    <h2>Parabéns</h2>
                                                                                    <p>A demanda que você esta visualizando foi concluída com sucesso. No momento se encontra fechada.</p>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="counter">
                                                                                        <h3 ref={dayCounter}>{dateDiff(proposalWork.Details.accept_at, datePlusWeek(proposalWork.Details.accept_at, parseInt(proposalWork.Details.deadline)))}</h3>
                                                                                        <p>Dias para conclusão</p>
                                                                                    </div>
                                                                                    <div className="increaseDeadline">
                                                                                        <InputStyle
                                                                                            onChange={(e) => setIncreaseDeadline(e.target.value)}
                                                                                            valueChanges={increaseDeadline}
                                                                                            required
                                                                                            placeholder="Prazo em (semanas)"
                                                                                            type="number"
                                                                                            title="Aumentar prazo de conclusão"
                                                                                            name="deadline"
                                                                                            className="text-double"
                                                                                        />
                                                                                        <button onClick={() => increaseDeadlineFunc()}>Aumentar</button>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="infoBox">
                                                                    <h1>Plano proposto</h1>
                                                                    <div className="content">
                                                                        <p className="text">{proposalWork.description.trim()}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="infoBox">
                                                                    <h1>Equipe</h1>
                                                                    <div className="content">
                                                                        <div className="teamList">
                                                                            {team.length !== 0 && team.map((item: ITeam, index: any) => (
                                                                                <div className="item" key={index}>
                                                                                    <div style={{ backgroundColor: gerarCorAleatoria() }} className="icon">
                                                                                        <AiOutlineUser size={20} color="#fff" />
                                                                                    </div>
                                                                                    <div className="carg">
                                                                                        <h4>{item.name}</h4>
                                                                                        <p>Sem função</p>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)

                                                        }
                                                    </>
                                                )
                                                }
                                            </>
                                        )

                                        }
                                    </>
                                )
                                }
                            </>
                        )
                    }
                </div>
                {finishDemand && (
                    <div className="footer">
                        <button className={`accept ${(descButton) ? "disabled" : ""}`} onClick={() => closeDemand()} disabled={descButton}>{(false) ? <CircleLoading color="#fff" /> : "Enviar"}</button>
                        <button className="denied" onClick={() => setFinishDemand(false)}>Voltar</button>
                    </div>
                )
                }
            </div>
        </Container>
    )
}