import React, { useEffect } from "react";
import Banner from "assets/img/banner";
import ImageEixos from "assets/icons/eixos";
import { splitTitle } from "util/function";
import NavBar from "components/NavBar";
import DoubtedCard from "components/Card/Doubt";
import CarrouselComp from "../components/Carrousel";
import { ContainerPage } from "./css/styled";
import CardDemandas from "../components/Card/BannerDemanda";
import { logoIcon } from "../assets/icons";
import CardAxes from "../components/Card/Axes";
import CardNews from "../components/Card/News";
import OtherNews from "../components/Card/OtherNews";
import ButtonDefault from "../components/Buttons/ButtonDefault";
import cityOther from "../assets/img/city_retangle.png";
import SponsorList from "../components/Carrousel/Sponsor";
import { ListComponent } from "components/ListComponent";
import { AboutUs } from "components/AboutUs";
import { useDispatch, useSelector } from "react-redux";
import { IStateData } from "interfaces/components.interface";
import { AppDispatch } from "app/store";
import { fetchDemandsThunk } from "app/reducers";

const TITLE =
  "Conselho dos Lindeiros solidifica parcerias para projetos estruturantes na região";

function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const { demands } = useSelector((state: IStateData) => state);
  const { news } = useSelector((state: IStateData) => state);

  useEffect(() => {
    dispatch(fetchDemandsThunk());
  }, [dispatch]);
  return (
    <>
      <ContainerPage>
        <NavBar text="home" />
        <div style={{ height: "65vh", position: "relative" }}>
          <CarrouselComp
            title=""
            image={[...Banner]}
            description="dsadadjasdja"
          />
          <div style={{ position: "absolute", top: "0", width: "100%", height: "100%", backgroundImage: "linear-gradient(120deg,#061a2a 0%,#1b4977 100%)", opacity: "0.85" }}></div>
        </div>
        <div className="limiter">
          {demands.demand[0] !== null && demands.demand[0] !== undefined && (
            <ListComponent title="Ultimas demandas" link="/demandas" objDemand={demands.demand.slice(0, 3)} />
          )
          }
          {news.news[0] !== null && news.news[0] !== undefined && (
            <ListComponent title="Ultimas noticias" link="/noticias" objNews={news.news.slice(0, 3)} />
          )
          }
        </div>
        <AboutUs />
        <div className="sponsor-content">
          <SponsorList />
        </div>
      </ContainerPage>
    </>
  );
}

export default Home;
