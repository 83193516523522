import API from "API";
import { AxiosError } from "axios";
import { HEADERS_DATA, HEADERS_DATA_POST, TokenUser } from "config";
import { IEProposal, IProposal, IProposalPost } from "interfaces/data/demand.interface";
import QueryString from "qs";

const RegisterProposal = async (proposalSave: IProposalPost) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA, token: `${token}` };
    const Proposal = await API("/proposal", {
      headers,
      method: "POST",
      data: proposalSave,
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    if (Proposal.isValid) {
      return {
        status: 200,
        message: Proposal.message,
        data: Proposal.data
      };
    }
    return {
      status: 400,
      message: Proposal.error,
    };
  } catch (err: any) {
    return {
      status: 404,
      message: err.message,
    };
  }
};

export const updateProposal = async (proposalSave: IEProposal) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA_POST, token: `${token}` };
    const Proposal = await API(`/proposal/${proposalSave.id}`, {
      headers,
      method: "PUT",
      data: QueryString.stringify(proposalSave)
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    if (Proposal.isValid) {
      return {
        status: 200,
        message: "Proposta atualizada com sucesso!",
      };
    }
    return {
      status: 400,
      message: "Tente novamente mais tarde",
    };
  } catch (err: any) {
    return {
      response: "",
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};

export const updateStatus = async (proposalSave: any) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA_POST, token: `${token}` };
    const Proposal = await API(`/proposalChangeState/${proposalSave.id}`, {
      headers,
      method: "PUT",
      data: QueryString.stringify(proposalSave)
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    
    if (Proposal.isValid) {
      return {
        status: 200,
        message: "Proposta aceita com sucesso!",
        data: Proposal.data.Demand
      };
    }
    return {
      status: 400,
      message: Proposal.error,
    };
  } catch (err: any) {
    console.log(err);
    return {
      response: "",
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};

export const updateDeadLine = async (detailId: string, weeks: string) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA_POST, token: `${token}` };
    const Proposal = await API(`/proposalChangeDeadline/${detailId}`, {
      headers,
      method: "PUT",
      data: QueryString.stringify({weeks:weeks})
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    if (Proposal.isValid) {
      return {
        status: 200,
        message: "Proposta atualizada com sucesso!",
      };
    }
    return {
      status: 400,
      message: Proposal.error,
    };
  } catch (err: any) {
    return {
      response: "",
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};

export const bondRequest = async (description: string, demandId: string, proposalId: string) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA_POST, token: `${token}` };
    const Proposal = await API(`/bondRequest/${proposalId}`, {
      headers,
      method: "POST",
      data: {description, demandId}
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    if (Proposal.isValid) {
      return {
        status: 200,
        message: "Pedido realizado com sucesso, aguarde a resposta por e-mail",
      };
    }
    return {
      status: 400,
      message: Proposal.error,
    };
  } catch (err: any) {
    return {
      response: "",
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};

export const acceptBondRequest = async (proposalId: string, demandId: string) => {
  try {
    const token = TokenUser();
    const headers = { ...HEADERS_DATA_POST, token: `${token}` };
    const Proposal = await API(`/acceptBondRequest/${proposalId}`, {
      headers,
      method: "POST",
      data: {demandId}
    })
      .then((response) => response.data)
      .catch((err: AxiosError) => err);
    if (Proposal.isValid) {
      return {
        status: 200,
        message: "Pedido realizado com sucesso, aguarde a resposta por e-mail",
      };
    }
    return {
      status: 400,
      message: Proposal.error,
    };
  } catch (err: any) {
    return {
      response: "",
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};



export default {
  register: RegisterProposal,
  update: updateProposal
};
