import { Container } from "../ProposalList/style";
import { FaCity, FaTimes, FaUsers } from "react-icons/fa";
import { BsFillTrash2Fill, BsCheckLg, BsStopwatch } from "react-icons/bs";
import { AiOutlineArrowLeft, AiOutlineUser, AiTwotoneCalendar } from "react-icons/ai";
import { IDemand, IProposal, ITeam } from "interfaces/data/demand.interface";
import { useEffect, useRef, useState } from "react";
import { GetMonth } from "util/getMonth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { findOneDemands } from "API/Demand/find.demand";
import { acceptBondRequest, bondRequest, updateDeadLine, updateProposal, updateStatus } from "API/Demand/Proposital/crud.proposal";
import { findByDemandProposal, findProposal, proposalBond } from "API/Demand/Proposital/find.proposal";
import { findTeam } from "API/Team/find.team";
import { dataFormat, dateDiff, datePlusWeek } from "util/dateFormater";
import { convertToArray } from "util/handleSelectorObj";
import { BiCheck, BiCheckCircle, BiMailSend } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import TextArea from "components/Textarea";
import { CircleLoading } from "components/circleLoading";
import { apiUrl, url } from "config";
import { formatKeyTypes, showErrorMessage } from "util/function";
import { LoadingDefault } from "components/Loading";
import InputStyle from "components/Inputs";
import { closeDemandReq } from "API/Demand/crud.demand";
import check from '../../assets/img/check.png';
import sino from '../../assets/icons/sino.png';
import { IStateData } from "interfaces/components.interface";
import { useNavigate } from "react-router";
import { SelectMenuAlternative } from "components/Select/Alterntive";
import { findAllByUsersThunk } from "app/reducers/demand/thunk";


interface IProposalList {
    state: boolean;
    setState: any;
    item: IProposal;
    type?: string;
}

export const ProposalManager = ({ state, setState, item, type }: IProposalList) => {
    let dayCounter = useRef<any>("");
    const { auth } = useSelector((state: IStateData) => state);
    const { demands } = useSelector((state: IStateData) => state);
    const nav = useNavigate();
    const [team, setTeam] = useState([]);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState<any>();
    const [finishDemand, setFinishDemand] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const [description, setDescription] = useState("");
    const [demand, setDemand] = useState("none");
    const [demandList, setDemandList] = useState<any>([]);


    useEffect(() => {
        if (state) {
            if (item !== undefined) {
                verifyBondRequest(item.id);

                if (item.Team !== undefined) {
                    let team: any = item.Team;
                    team = convertToArray(team.Team);
                    setTeam(team);
                }
            }
        }
    }, [state])

    const verifyBondRequest = async (id: string) => {
        let req = await proposalBond(id);
        if (req.isValid) {
            setDemandList(convertToArray(req.data.Demand))
        }
    }

    const sendBondRequest = async () => {
        if (description.length === 0 || demand === "none") {
            showErrorMessage("Por fvaor, preencha todos os dados", "error");
            return;
        }

        if (item) {
            setLoading(true);
            let req = await bondRequest(description, demand, item?.id);
            setLoading(false);

            if (req.status === 200) {
                setDescription("");
                setDemand("");
                setFinishDemand(false);
            }
            showErrorMessage(req.message, (req.status === 200 ? "success" : "error"));
        }
    }

    const handlePropUsername = () => {
        let name = "Publico";

        if (item?.User !== undefined) {
            if (item.User) {
                name = item.User.name;
            }
        }

        return name;
    }

    const handleBondRequest = async (demandId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (demandId && confirm("Ao confirmar, você será automaticamente designado para trabalhar com o demandante. Deseja prosseguir?")) {
            setLoading(true);
            const resp = await acceptBondRequest(item.id, demandId);
            if (resp.status === 200){
                showErrorMessage("Vínculo criado com sucesso", "success")
            }else{
                showErrorMessage(resp.message, "error")
            }

            setLoading(false);
            nav("/painel/propostas");
        }
    }

    function gerarCorAleatoria() {
        const letrasHex = '0123456789ABCDEF'; // Define as letras permitidas em hexadecimais
        let cor = '#'; // Começa a cor com o símbolo "#" para indicar que é um valor hexadecimal

        // Gera seis caracteres aleatórios em hexadecimal e adiciona à cor
        for (let i = 0; i < 6; i++) {
            cor += letrasHex[Math.floor(Math.random() * 16)];
        }

        return cor; // Retorna a cor gerada
    }

    function copyEmail(email: string) {
        navigator.clipboard.writeText(email)
            .then(() => {
                setCopy(true);

                setTimeout(() => {
                    setCopy(false);
                }, 2000)
            })
            .catch((error) => {
                console.error('Erro ao copiar texto:', error);
            });
    }

    function handleRequestProposal() {
        if (!auth.auth.logged) {
            showErrorMessage("Você precisa estar logado para executar essa ação", "error");
            return;
        }

        dispatch(findAllByUsersThunk(auth.auth.user[0].id));
        setFinishDemand(true);
    }

    return (
        <Container state={state}>
            <LoadingDefault
                active={loading || demands.loading}
            />
            <div className="body">
                <div className="title">
                    <div className="info">
                        <h1>Detalhes da Propostas</h1>
                        <h3>Dded</h3>
                    </div>
                    <span style={{ cursor: "pointer" }} onClick={() => { setState(false); setDetails(undefined) }}><FaTimes size={25} color="#8f9497" /></span>
                </div>
                <div className="content">
                    <>
                        <div className="controls">
                            <div className="tag" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)", marginBottom: "12px", paddingBottom: "12px" }}>
                                <div className="status-2"></div>
                                <h1>Proposta em aberto&nbsp;&nbsp;&nbsp;</h1>
                            </div>
                            <div className="buttons">
                                <>
                                    <button onClick={() => alert("Em desenvolvimento")}>Gerenciar equipe &nbsp;&nbsp;<div className="icon"><FaUsers size={15} /></div></button>
                                </>
                            </div>
                        </div>
                        {demandList.length !== 0 && demandList[0] !== undefined && type !== "general" && (
                            <div className="table">
                                <div className="infoBox">
                                    <h1>Pedidos de vínculo</h1>
                                    <div className="content" style={{ padding: 0 }}>
                                        <table style={{ marginTop: 0 }}>
                                            <thead>
                                                <th>Ver mais</th>
                                                <th>titulo</th>
                                                <th>Demandante</th>
                                                <th>Eixo</th>
                                                <th>Ações</th>
                                            </thead>
                                            <tbody>
                                                {demandList.map((demandItem: IDemand, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ paddingLeft: 0 }}><a href={url + "demanda/" + demandItem.id} target="_blank" rel="noreferrer"><p className="field-button" style={{ textAlign: "center" }}>Ver mais</p></a></td>
                                                        <td style={{ textAlign: "center" }}>{demandItem.name}</td>
                                                        <td>{demandItem.User.name}</td>
                                                        <td>{demandItem.Axes.name}</td>
                                                        <td>
                                                            <span className="acceptButton" onClick={() => handleBondRequest(demandItem.id)}>Aceitar</span>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )

                        }
                        <div className="infoBox">
                            <h1>Detalhes</h1>
                            <div className="content">
                                <div className="left">
                                    <div className="item">
                                        <h2>Proponente</h2>
                                        <p>{handlePropUsername()}</p>
                                    </div>
                                    <div className="item">
                                        <h2>Orçamento</h2>
                                        <p>R$ {item && item.Details.value}</p>
                                    </div>
                                    <div className="item">
                                        <h2>E-mail</h2>
                                        <p><a href={`mailto:?subject=Plataforma de governança - Duvida sobre proposta`}>{item && item.email}</a></p>
                                    </div>
                                    <div className="item">
                                        <h2>Prazo de conclusão</h2>
                                        <p>{item && item.Details.deadline + " semanas"}</p>
                                    </div>
                                </div>
                                <div className="right">
                                    {item.isAproved === "1" ? (
                                        <div className="counter">
                                            <h3 ref={dayCounter}>{dateDiff(item.Details.accept_at, datePlusWeek(item.Details.accept_at, parseInt(item.Details.deadline)))}</h3>
                                            <p>Dias para conclusão</p>
                                        </div>
                                    ) : (
                                        <div className="congratulation">
                                            <img src={check} width="40px" alt="" />
                                            <h2>Em stand by</h2>
                                            <p>No momento, sua proposta está sendo analisada pelo demandante. Por favor, aguarde.</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="infoBox">
                            <h1>Plano proposto</h1>
                            <div className="content">
                                <p className="text">{item && item.description.trim()}</p>
                            </div>
                        </div>
                        <div className="infoBox">
                            <h1>Equipe</h1>
                            <div className="content">
                                <div className="teamList">
                                    {team.length !== 0 && team.map((item: ITeam, index: any) => (
                                        <div className="item" key={index}>
                                            <div style={{ backgroundColor: gerarCorAleatoria() }} className="icon">
                                                <AiOutlineUser size={20} color="#fff" />
                                            </div>
                                            <div className="carg">
                                                <h4>{item.name}</h4>
                                                <p>Sem função</p>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                </div>
                {finishDemand && (
                    <div className="footer">
                        <button className={`accept ${(description.length === 0 || demand === "none") ? "disabled" : ""}`} onClick={() => sendBondRequest()} disabled={description.length === 0 || demand === "none"}>{(false) ? <CircleLoading color="#fff" /> : "Enviar"}</button>
                        <button className="denied" onClick={() => setFinishDemand(false)}>Voltar</button>
                    </div>
                )}
            </div>
        </Container>
    )
}