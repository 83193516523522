import fundacao from "./fundacao.png";
import itaipu from "./itaipu.png";
import lindeiros from "./lindeiros.png";
import parana from "./parana.png";
import unioeste from "./unioeste.png";
import oeste from "./oeste.png";
import utfpr from "./utfpr.png";

export const ImgSponsors = [
    {
        photo: fundacao,
        link: "https://www.fappr.pr.gov.br/"
    },
    {
        photo: itaipu,
        link: "https://www.itaipu.gov.br/"
    },
    {
        photo: lindeiros,
        link: "https://www.lindeiros.org.br/"
    },
    {
        photo: parana,
        link: "https://www.parana.pr.gov.br/"
    },
    {
        photo: unioeste,
        link: "https://www.unioeste.br/portal/"
    },
    {
        photo: oeste,
        link: "https://oestedesenvolvimento.com.br/"
    },
    {
        photo: utfpr,
        link: "http://www.utfpr.edu.br/"
    },
]
