/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { IDataProposal } from "interfaces/data/demand.interface";
import { createProposalThunk, fetchProposalRelatedDemand, fetchProposalRelatedDemandById, fetchProposalUnrelated, fetchProposalUnrelatedByUser, findOneProposal, updateProposal } from "./thunk";
import { IStateData } from "interfaces/components.interface";
import { convertToArray } from "util/handleSelectorObj";

const initialState: IDataProposal = {
  loading: false,
  proposal: [],
  fullProposal: [],
  item: undefined,
  secondaryLoading: false,
  error: "",
  status: 0
};

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(
      createProposalThunk.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;
        console.log(payload);
        if (payload.data) {
          console.log(payload.data);
        }

        if (payload !== undefined) {
          state.loading = true;
          state.message = payload.message;
        }

        state.loading = false;
      },
    );
    builder.addCase(
      fetchProposalUnrelated.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;

        if(payload.response.ProposalList){
          state.proposal = payload.response.ProposalList;
          state.fullProposal = payload.response.ProposalList;
        }

        state.loading = false;
      },
    );
    builder.addCase(
      fetchProposalUnrelated.pending,
      (state: IDataProposal) => {
        state.loading = true;
      },
    );
    builder.addCase(
      createProposalThunk.pending,
      (state: IDataProposal) => {
        state.loading = true;
      },
    );
    builder.addCase(
      findOneProposal.pending,
      (state: IDataProposal) => {
        state.secondaryLoading = true;
      },
    );
    builder.addCase(
      findOneProposal.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;

        state.item = payload.response.Proposal;
        state.secondaryLoading = false;
      },
    );
    builder.addCase(
      fetchProposalRelatedDemand.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;

        console.log(payload.response);
        state.proposal = convertToArray(payload.response);
        state.fullProposal = convertToArray(payload.response);
        state.loading = false;
      },
    );
    builder.addCase(
      fetchProposalUnrelatedByUser.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;

        console.log(payload.response);
        state.proposal = convertToArray(payload.response.ProposalList);
        state.fullProposal = convertToArray(payload.response.ProposalList);
        state.loading = false;
      },
    );
    builder.addCase(
      fetchProposalUnrelatedByUser.pending,
      (state: IDataProposal) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchProposalRelatedDemandById.fulfilled,
      (state: IDataProposal, action) => {
        const { payload } = action;

        console.log(payload.response);
        state.proposal = convertToArray(payload.response);
        state.fullProposal = convertToArray(payload.response);
        state.loading = false;
      },
    );
    builder.addCase(
      fetchProposalRelatedDemandById.pending,
      (state: IDataProposal) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchProposalRelatedDemand.pending,
      (state: IDataProposal) => {
        state.loading = true;
      },
    );
    builder.addCase(
      updateProposal.pending,
      (state: IDataProposal) => {
        state.secondaryLoading = true;
      },
    );
    builder.addCase(
      updateProposal.fulfilled,
      (state: IDataProposal, action) => {
        state.secondaryLoading = false;
        state.status = action.payload.status;
      },
    );

  },
  reducers: {
    insertProposal: () => { },
    allProposal: () => { },
    clean: (state: IDataProposal) => {
      state.status = 0;
    },
    getItem: (state: IDataProposal, action) => {
      if(state.proposal.length > 0){
        state.item = state.proposal.filter(item => item.id === action.payload)[0];
      }
    },
    filterAll: (state: IDataProposal, action) => {
      let { payload } = action;

      let temp = state.fullProposal;
      let stateEmpty = (payload.stateSelector.length === 0);

      if (!stateEmpty) {
        if(payload.stateSelector === "1"){
          temp = temp.filter((proposal) => !proposal.User);
        }else{
          temp = temp.filter((proposal) => proposal.User);
        }
      }

      if (payload.searchSelector.length > 0) {
        temp = temp.filter((proposal) => proposal.description.toLocaleLowerCase()
          .includes(payload.searchSelector.toLocaleLowerCase()));
      }

      if (stateEmpty && payload.searchSelector.length == 0) {
        temp = state.fullProposal;
      }

      state.proposal = temp;
    },
    cleanProposal: (state: IDataProposal) =>{
      state.fullProposal = [];
      state.proposal = [];
      state.item = undefined;
    }
  },
});

export const { insertProposal, allProposal, clean, getItem, filterAll, cleanProposal } = proposalSlice.actions;

export default proposalSlice.reducer;
export const proposalStatus = (state: IStateData) => state.proposal.status;

