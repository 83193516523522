import { BiCheck } from "react-icons/bi"
import { ConfirmContainer } from "./css/styled"
import { url } from "config"
import { useNavigate, useParams } from "react-router"
import { useEffect, useState } from "react"
import { checkOutsider } from "API/Links/links"
import { showErrorMessage } from "util/function"
import { LoadingDefault } from "components/Loading"

export const ConfirmProposal = () => {
    const { link } = useParams();
    const [allow, setAllow] = useState(false);
    const [demandaLink, setDemandaLink] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        checkInformation();
    }, [])

    const checkInformation = async () => {
        if (link !== undefined) {
            let resp = await checkOutsider(link);

            if(resp.status === 400){
                showErrorMessage(resp.message, "error");
                nav("/demandas");
                return;
            }

            setDemandaLink(resp.id);
            setAllow(true);
        }
    }

    return allow ? (
        <ConfirmContainer>
            <div className="box">
                <div className="check">
                    <BiCheck size={100} color="#71ce5c" />
                </div>
                <h1>Sucesso</h1>
                <p>Seu e-mail foi confirmado com sucesso. Sua proposta foi enviada. clique no link a seguir para acessar a demanda. <a href={url + `demanda/${demandaLink}`}>Clique aqui</a></p>
            </div>
        </ConfirmContainer>
    ): (
        <LoadingDefault
          active={true}
        />
    )
}