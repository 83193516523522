export const eixoData = [
  {
    eixo: 1,
    description:
      "A governança inteligente é a gestão competente dos recursos disponíveis utilizando informações e suas tecnologias...",
    fullText:
      "A governança inteligente é a gestão competente dos recursos disponíveis utilizando informações e suas tecnologias para suporte e promoção da transformação do território. Trata-se da perspectiva integrada e estratégica do planejamento e execução do Programa de Governança Regional – PGR. Podem ser utilizadas, de forma articulada e integrada, metodologias/programas do Governo do Estado do Paraná, da Itaipu Binacional, da Fundação Araucária em parceria com as Câmaras Temáticas do POD - Programa Oeste em Desenvolvimento.",
    url: "1",
    title: "Governança Inteligente e Integrada",
    icon: "hotel",
  },
  {
    eixo: 2,
    description:
      "Projetos, metodologias e ações para modernizar a gestão pública municipal com formação continuada dos servidores...",
    fullText:
      "Projetos, metodologias e ações para modernizar a gestão pública municipal com formação continuada dos servidores públicos, utilização de sistemas digitais com tecnologia de informação para planejamento e oferta de serviços eficientes aos cidadãos. Interação e troca de experiências, metodologias e formas de gestão entre as prefeituras do oeste do Paraná. Busca pela maior eficiência com a digitalização/automatização dos processos e serviços públicos. ",
    url: "2",
    title: "Gestão Pública",
    icon: "id-card",
  },
  {
    eixo: 3,
    description:
      "A sustentabilidade é tema transversal e abarca as dimensões sociais, ambientais e econômicas. O desafio da sustentabilidade...",
    fullText:
      "A sustentabilidade é tema transversal e abarca as dimensões sociais, ambientais e econômicas. O desafio da sustentabilidade é desenvolver e executar ações efetivas que gerem a transformação e que possam ser mensuráveis com informações e indicadores. Neste eixo são contemplados os ODS – Objetivos do Desenvolvimento Sustentável que se relacionam às áreas de atuação no território dos lindeiros.",
    url: "3",
    title: "Sustentabilidade",
    icon: "tree",
  },
  {
    eixo: 4,
    description:
      "Busca pela autonomia das pessoas e seu desenvolvimento humano, social e profissional sintonizado com as tendências e demandas contemporâneas...",
    fullText:
      "Busca pela autonomia das pessoas e seu desenvolvimento humano, social e profissional sintonizado com as tendências e demandas contemporâneas. Promoção e apoio ao autodesenvolvimento, empreendedorismo e liderança. Atividades e ações que visem a promoção do esporte, cultura e lazer.",
    url: "4",
    title: "Educação, Esporte, Cultura e Lazer",
    icon: "briefcase",
  },
  {
    eixo: 5,
    description:
      "Apoio e fortalecimento dos negócios (atividades econômicas) atuais e fomento com inovação e tecnologia para a prospecção de novos negócios...",
    fullText:
      "Apoio e fortalecimento dos negócios (atividades econômicas) atuais e fomento com inovação e tecnologia para a prospecção de novos negócios. Programas e metodologias para atração de investimentos no território dos lindeiros.",
    url: "5",
    title: "Fomento e Prospecção de Negócios",
    icon: "heartbeat",
  },
  {
    eixo: 6,
    description:
      "Projetos e ações que visem o atendimento da saúde da população. Atenção às ações e metodologias que visem a saúde preventiva e qualidade de vida da população...",
    fullText:
      "Projetos e ações que visem o atendimento da saúde da população. Atenção às ações e metodologias que visem a saúde preventiva e qualidade de vida da população. Promoção de ações e políticas públicas integradas, com tecnologias de informação, para atendimento da população.",
    url: "6",
    title: "Promoção da Saúde",
    icon: "university",
  },
  {
    eixo: 7,
    description:
      "Participação ativa das universidades públicas e particulares na transformação dos municípios lindeiros e oeste do Paraná, na indução de competências inovadoras...",
    fullText:
      "Participação ativa das universidades públicas e particulares na transformação dos municípios lindeiros e oeste do Paraná, na indução de competências inovadoras e tecnológicas transversalmente nas atividades de ensino (formação), pesquisa (inovação) e extensão (inserção ativa na sociedade).",
    url: "7",
    title: "Inserção das Universidades",
    icon: "seedling",
  },
  {
    eixo: 8,
    description:
      "Entende-se por agricultura familiar a atividade agropecuária (ou multifinalitária) com força de trabalho na unidade de produção, na sua maioria, familiar...",
    fullText:
      "Entende-se por agricultura familiar a atividade agropecuária (ou multifinalitária) com força de trabalho na unidade de produção, na sua maioria, familiar. Em uma análise geral, encontram-se no território dos municípios lindeiros duas tipologias de agricultura familiar: agricultura familiar de subsistência e a empresarial integrada às cadeias produtivas. Cada tipo demanda por projetos e ações específicas de acordo com suas características e visam a qualidade de vida, uso equilibrado e racional dos recursos naturais, e, sua viabilidade técnica e econômica.",
    url: "8",
    title: "Apoio à Agricultura Familiar",
    icon: "laptop-code",
  },
  {
    eixo: 9,
    description:
      "A inovação pode ser incremental, radical ou disruptiva. Entende-se por inovação a aplicação do...",
    fullText:
      "A inovação pode ser incremental, radical ou disruptiva. Entende-se por inovação a aplicação do conhecimento que gera transformação econômica e social. É fundamental o desenvolvimento e promoção da cultura e dos ecossistemas de inovação que possam contribuir na competitividade nos arranjos produtivos e prospecção de novos negócios.",
    url: "9",
    title: "Promoção da Inovação",
    icon: "drumstick-bite",
  },
  {
    eixo: 10,
    description:
      "As cadeias produtivas de carnes, grãos e alimentos são fundamentais e configuram a economia do...",
    fullText:
      "As cadeias produtivas de carnes, grãos e alimentos são fundamentais e configuram a economia do território dos municípios lindeiros. A competitividade das cadeias produtivas depende da sua coordenação e ajustes com a utilização de tecnologias digitais e informacionais, infraestrutura logística, conectividade, energia e água. Outro aspecto de atenção está relacionado à garantia da sanidade agropecuária que afeta diretamente a dinâmica das cadeias produtivas.",
    url: "10",
    title: "Competitividade das Cadeias Produtivas",
    icon: "sitemap",
  },
  {
    eixo: 11,
    description:
      "Identificar e propor projetos integrados que atendam as demandas de infraestrutura...",
    fullText: "Identificar e propor projetos integrados que atendam as demandas de infraestrutura (rodovias, ciclovias, conectividade, água e energia, entre outros) do território dos municípios lindeiros que servirão de base e apoio para o desenvolvimento das atividades econômicas e qualidade de vida da população.",
    url: "11",
    title: "Infraestrutura Integrada e Regional",
    icon: "shield-alt",
  },
  {
    eixo: 12,
    description:
      "As características do território dos lindeiros demandam atenção especializada, projetos e...",
    fullText: "As características do território dos lindeiros demandam atenção especializada, projetos e ações que garantam a segurança da população na faixa de faixa de fronteira. A utilização de tecnologias, sistemas e equipamentos para promoção da segurança é fator estratégico e de competitividade do território dos municípios lindeiros.",
    url: "12",
    title: "Segurança Pública",
    icon: "shield-alt",
  }
];
