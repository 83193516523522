import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai"
import { Container, Menu, Photos } from "./styles"
import { FaTimes } from "react-icons/fa"

interface Iprops{
    setState: any;
    photo: string;
}

export const Gallery = ({setState, photo}:Iprops) => {
    return (
        <Container>
            <Photos>
                <button className="close" onClick={() => setState(false)}><FaTimes size={15} color="#fff"/></button>
                <div className="img" style={{backgroundImage: `url(${photo})`}}></div>
                {/* <Menu>
                    <button><AiFillCaretLeft size={15} color="#fff"/></button>
                    <div className="info">1 de 2</div>
                    <button><AiFillCaretRight size={15} color="#fff"/></button>
                </Menu> */}
            </Photos>
        </Container>
    )
}