import React, { useEffect, useReducer, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";

import NavBar from "components/NavBar";
import { ContainerPage } from "pages/css/styled";
import { InputSearch } from "components/Inputs/Search";
import MenuSuspenso from "components/Card/MenuSuspenso";
import { CardDemandas } from "components/Card/Demandas";
import { useDispatch, useSelector } from "react-redux";
import { IStateData } from "interfaces/components.interface";
import { Link, useParams } from "react-router-dom";
import { LoadingDefault } from "components/Loading";
import { isValid } from "util/function";
import { AppDispatch } from "app/store";
import { selectCurentUser } from "app/reducers/auth/authSlice";
import { MdPlaylistAddCheck } from "react-icons/md";
import { GetMonth } from "util/getMonth";
import { AutenticateCard } from "components/AutenticateAdd";
import { fetchProposalUnrelated } from "app/reducers/proposital/thunk";
import { ProposalRequest } from "components/ProposalRequest";
import { cleanProposal, filterAll, getItem } from "app/reducers/proposital/propositalSlice";
import { OnlyText } from "components/AutenticateAdd/onlyText";

export default function Propostas() {
    const { id } = useParams();
    const [user, logged] = useSelector(selectCurentUser);
    const dispatch = useDispatch<AppDispatch>();
    const [filterState, setFilterState] = useState(false);
    const [proposalRequest, setProposalRequest] = useState(false);
    const { axes } = useSelector((state: IStateData) => state);
    const proposal = useSelector((state: IStateData) => state.proposal);

    const [searchSelector, setSearchSelector] = useState<string>();
    const [stateSelector, setStateSelector] = useState<string>();

    console.log(proposal.proposal)
    useEffect(() => {
        dispatch(cleanProposal());
        dispatch(fetchProposalUnrelated());
    }, [dispatch])

    useEffect(() => {
        if (id !== undefined) {
            setProposalRequest(true);
            dispatch(getItem(id));
        }
    }, [id])

    useEffect(() => {
        let stateTemp = stateSelector ?? "";
        let searchTemp = searchSelector ?? "";

        dispatch(filterAll({ "stateSelector": stateTemp, "searchSelector": searchTemp }));
    }, [stateSelector, searchSelector, dispatch]);

    const handleFilter = () => {
        if (filterState === true) {
            setFilterState(false);
        } else {
            setFilterState(true);
        }
    }

    const checktitle = (title: string) => {
        let min = title.substring(0, 40) + "...";
        return (title.length >= 40) ? min : title;
    }

    const cleanFilter = () => {
        setStateSelector("");
        setSearchSelector("");
    }

    function dataFormat(data: string): string {
        let format = new Date(data);
        let formatString = format.toLocaleDateString("pt-BR").split("/");
        let text = `${formatString[0]} de ${GetMonth(parseInt(formatString[1]))}, ${formatString[2]}`;

        return text;
    }

    return (
        <>
            <NavBar text="propostas" />
            {proposal.item !== undefined && <ProposalRequest setState={setProposalRequest} state={proposalRequest} />}
            <ContainerPage>
                <LoadingDefault
                    active={proposal.loading}
                />
                <span className="ResponsiveFilter" onClick={handleFilter}>
                    <BiFilter color="#333" size="35" style={{ display: (filterState) ? "none" : "flex" }} />
                    <FaTimes color="#333" size="35" style={{ display: (filterState) ? "flex" : "none" }} />
                </span>
                <div className="container-banner-demandas">
                    <div className="header" />
                    <div className="data">
                        <div className="left-demandas" style={{ display: (filterState) ? "flex" : "none" }}>
                            <MenuSuspenso className="menu-suspenso-demandas">
                                <>
                                    <div className="filters-demandas-modal" style={{ marginBottom: "20px" }}>
                                        <h1 className="title-h2">Pesquisa por proponente</h1>
                                        <div className="search">
                                            <span className="check">
                                                <input
                                                    id="execution"
                                                    type="radio"
                                                    name="state"
                                                    checked={(stateSelector === "1") ? true : false}
                                                    onChange={(e) =>
                                                        setStateSelector("1")
                                                    }
                                                />
                                                <label htmlFor="execution">Público</label>
                                            </span>
                                            <span className="check">
                                                <input
                                                    id="close"
                                                    type="radio"
                                                    checked={(stateSelector === "2") ? true : false}
                                                    name="state"
                                                    onChange={(e) =>
                                                        setStateSelector("2")
                                                    }
                                                />
                                                <label htmlFor="close">Vinculado a uma conta</label>
                                            </span>
                                        </div>
                                    </div>
                                    <button className="btnAddDemand" onClick={() => cleanFilter()}>Limpar filtro <MdPlaylistAddCheck size="25" /></button>
                                </>
                            </MenuSuspenso>
                        </div>
                        <div className="right-demandas">
                            <div className="search-demandas">
                                <InputSearch
                                    setState={setSearchSelector}
                                    size="100%"
                                    borderRadius="4px"
                                    color="white"
                                    valueDefault={searchSelector}
                                    text="Pesquisar"
                                    background="#1B4977"
                                    height="55px"
                                />
                            </div>
                            <div className="bodyTop" style={{ marginTop: "80px", marginBottom: "-15px" }}>
                                <OnlyText title="Banco de proposta" text="Bem-vindo à página de propostas independentes! Aqui você encontrará propostas que não estão vinculadas a outras demandas e estão prontas para iniciar um trabalho." />
                            </div>
                            {proposal.proposal && proposal.proposal.length && proposal.proposal[0] !== null && proposal.proposal[0] !== undefined ? (
                                <div className="cards-demandas">
                                    {proposal.proposal &&
                                        proposal.proposal.map((item: any) => {
                                            return (
                                                <div className="demandaCardItem" key={item.id}>
                                                    <Link to={`/propostas/${item.id}`}>
                                                        <CardDemandas
                                                            key={item.id}
                                                            className="box-demanda"
                                                            color="#8BEFBF"
                                                            // "Turismo integrado no te..."
                                                            title={checktitle(item.description)}
                                                            // "Eixo - Negócios e renda"
                                                            subtitle={(item.name && item.name.length === 0) ? "Publico" : item.name}
                                                            date={dataFormat(item.createdAt)}
                                                        />
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                </div>
                            ) : (
                                <div className="notFound">Nenhum dado cadastrado</div>
                            )}
                        </div>
                    </div>
                </div>
            </ContainerPage>
        </>
    );
}
