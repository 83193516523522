/* eslint-disable consistent-return */
import { AxiosError } from "axios";
import { HEADERS_DATA } from "config";
import API from "..";

export const checkOutsider = async (token: string) => {
  try {
    const headers = { ...HEADERS_DATA };
    const data = await API(`/outsiderCheck`, {
      method: "POST",
      headers,
      data: { token }
    })
      .then((response) => Promise.resolve(response.data))
      .catch((err: Error | AxiosError) => Promise.resolve(err));
    if (data.isValid) {
      return {
        status: 200,
        message: "Conta verificada com sucesso",
        id: data.id
      };
    }
    return {
      status: 400,
      message: data.error,
    };
  } catch (err: any) {
    return {
      status: 404,
      message: "Contate o administrador do sistema",
    };
  }
};