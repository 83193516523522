import { IPropsGlobal } from "interfaces/components.interface"
import { Container } from "./style"

export const OnlyText = ({ title, text }: IPropsGlobal) => {

    return (
        <Container className="full">
            <div className="contentArea">
                <h1>{title}</h1>
                <p className="tall">{text}</p>
            </div>
        </Container>
    )
}