import styled from "styled-components";

interface Props {
    state: boolean;
}

export const Container = styled.div<Props>`
    display: ${(props) => (props.state) ? "flex" : "none"};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 50;

    .body{
        animation: fadeIn 0.5s ease-out forwards;
        transform: translateY(150px);
        width: 70%;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 0.15);

        .title{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0 30px;

            .info{
                h1{
                    color: var(--color-background);
                    font-size: 1.3rem;
                }
                h3{
                    font-size: 0.95rem;
                    opacity: 0.8;
                    font-weight: normal;
                    margin-bottom: 0;
                }
            }
        }

        .content{
            overflow-y: auto;
            height: calc(100% - 80px);
            padding: 20px;

            .feedBack-form{
                height: 100%;
            }

            .subTitle{
                font-size: 0.95rem;
                font-weight: bold;

                span{
                    color: #ed5371;
                }
            }

            .controls{
                width: 100%;
                padding: 15px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 25px;

                .tag{
                    display: flex;
                    align-items: center;
                    .status-3{
                        padding: 5px;
                        width: 5px;
                        height: 5px;
                        background-color: #EF8B8B;
                        border-radius: 2px;
                        margin-right: 10px;
                    }
                    .status-2{
                        padding: 5px;
                        width: 5px;
                        height: 5px;
                        background-color: #8BEFBF;
                        border-radius: 2px;
                        margin-right: 10px;
                    }
                    .status-1{
                        padding: 5px;
                        width: 5px;
                        height: 5px;
                        background-color: #EFBA8B;
                        border-radius: 2px;
                        margin-right: 10px;
                    }
                    h1{
                        font-size: 16px;
                        font-weight: bold;
                        span{
                            font-weight: normal;
                            color: #aaa;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    .state{
                        padding: 5px 10px;
                        font-size: 12px;
                        margin-left: 15px;
                        color: #fff;
                    }
                    .state.status-3{
                        width: fit-content;
                        height: fit-content;
                        background-color: #EF8B8B;
                    }
                    .state.status-2{
                        width: fit-content;
                        height: fit-content;
                        background-color: #8BEFBF;
                    }
                    .state.status-1{
                        width: fit-content;
                        height: fit-content;
                        background-color: #EFBA8B;
                    }
                }

                .info{
                    padding-bottom: 12px;
                    margin-bottom: 12px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                    margin-top: 5px;
                    display: flex;
                    .item{
                        font-size: 14px;
                        margin-right: 15px;
                        color: #777;
                    }
                }

                .buttons{
                    display: flex;
                    column-gap: 10px;
                    align-items: center;

                    button{
                        font-size: 14px;
                        display: flex;
                        padding: 10px 15px;
                        border-radius: 25px;
                        background-color: transparent;
                        color: var(--color-background);
                        border: 1px solid var(--color-background);

                        &:hover{
                            background-color: var(--color-background);
                            color: #fff;
                        }
                    }
                }
            }

            .feedBack-form{
                h1{
                    font-size: 30px;
                    font-weight: bold;
                }
                p{
                    margin: 10px 0 25px;
                    font-size: 17px;
                    color: #777;
                    line-height: 25px;
                    width: 80%;
                }
            }

            .table{
                overflow-y: auto;
                max-height: 60%;
                table{
                    margin-top: 5px;
                    border: 0;
                    box-shadow: unset!important;
                    
                    thead{
                        height: 60px;

                        th{
                            padding-left: 30px;
                            font-size: 0.8rem;
                            color: #959191;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            &:first-of-type{
                                text-align: left;
                            }
                        }
                    }
                    tbody{
                        tr{
                            &:last-of-type{
                                td{
                                    border-bottom: 0;
                                }
                            }
                            td{
                                height: 60px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                                padding-left: 30px;
                                text-align: center;

                                &:nth-of-type(3){
                                    opacity: 0.75;
                                }
                                
                                &.aproved{
                                    font-size: 0.9rem;
                                    color: #3bd68b;
                                }

                                &.await{
                                    font-size: 0.9rem;
                                    color: #1d5e83;
                                }

                                &.cancelled{
                                    font-size: 0.9rem;
                                    color: #ed5371;
                                }

                                .field-button{
                                    border: 1px solid var(--color-background);
                                    color: var(--color-background);
                                    background: transparent;
                                    border-radius: 5px;
                                    font-weight: bold;

                                    &:hover{
                                        background: var(--color-background);
                                        color: #fff;
                                    }
                                }

                                span.acceptButton{
                                    cursor: pointer;
                                    font-size: 14px;
                                    padding: 5px 10px;
                                    background-color: #4aea38;
                                    color: #fff;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
            }

            .infoBox{
                margin-bottom: 25px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                h1{
                    font-size: 16px;
                    font-weight: bold;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    height: 50px;
                    display: flex;
                    align-items: center;
                    padding: 5px 15px;
                    margin-bottom: 0;
                }
                .content{
                    display: flex;
                    .text{
                        font-size: 1.1rem;
                        text-align: justify;
                        line-height: 30px;
                    }
                    .left{
                        border-right: 1px solid rgba(0, 0, 0, 0.1);
                        width: 50%;
                        display: grid;
                        grid-template-columns: auto auto;
                        .item{
                            margin-bottom: 25px;
                            h2{
                                font-size: 12px;
                                color: #999;
                                margin-bottom: 5px;
                            }
                            p{
                                font-size: 15px;
                                margin-right: 15px;
                                color: #555;
                            }
                            &:nth-of-type(3),
                            &:nth-of-type(4){
                                margin-bottom: 0;
                            }
                        }
                    }
                    .right{
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        width: 50%;
                        .counter{
                            h3{
                                font-size: 35px;
                                text-align: center;
                                margin-bottom: 5px;
                                color: var(--color-background);
                            }
                            p{
                                font-size: 15px;
                                color: #555;
                            }
                        }
                        .increaseDeadline{
                            button{
                                width: 100%;
                                text-align: center;
                                font-size: 14px;
                                background-color: transparent;
                                color: var(--color-background);
                                padding: 5px 15px;
                                border-radius: 15px;
                                border: 1px solid var(--color-background);
                            }
                        }
                    }
                    .congratulation{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        img{
                            margin-bottom: 15px;
                        }
                        h2{
                            font-size: 18px;
                            font-weight: bold;
                        }
                        p{
                            font-size: 14px;
                            color: #888;
                            text-align: center;
                            width: 80%;
                        }
                    }
                    .teamList{
                        display: grid;
                        grid-auto-columns: auto;
                        grid-template-columns: auto auto auto auto;
                        grid-column-gap: 25px;
                        width: 100%;
                        row-gap: 25px;
                        .item{
                            display: flex;
                            align-items: center;
                            margin-right: 2.5%;
                            &:nth-of-type(4n){
                                margin-right: 0;
                            }
                            .icon{
                                min-width: 40px;
                                width: 40px;
                                height: 40px;
                                margin-right: 15px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .carg{
                                h4{
                                    font-size: 16px;
                                    margin-bottom: 0px;
                                }
                                p{
                                    font-size: 14px;
                                    color: #777;
                                }
                            }
                        }
                    }
                }
            }

            .details{
                .backButton{
                    margin-bottom: 30px;
                    background: transparent;
                    color: var(--color-background);
                }
                .participants{
                    margin-bottom: 20px;
                    button{
                        margin-top: 5px;
                        border-radius: 5px;
                        height: 35px;
                        column-gap: 10px;
                        background-color: #f5f5f5;
                        border: 1px solid #d5d5d5;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        font-size: 11px;
                        letter-spacing: 1.2px;
                        text-align: center;
                        position: relative;
                        padding: 0 15px;
                        position: relative;
                        .copied{
                            content: "copiado";
                            background-color: #333;
                            border-radius: 5px;
                            padding: 5px;
                            border-radius: #222;
                            color: #fff;
                            font-size: 9px;
                            position: absolute;
                            right: 5px;
                            bottom: 30px;
                            animation: popIn 0.8s;
                            &::after{
                                content: "";
                                position: absolute;
                                bottom: -15px;
                                left: 60%;
                                transform: rotate(180deg);
                                border-width: 8px;
                                border-style: solid;
                                border-color: transparent transparent #333 transparent;
                            }
                            @keyframes popIn{
                                0%{
                                    opacity: 0;
                                    transform: translateY(10px);
                                }
                                100%{
                                    opacity: 1;
                                    transform: translateY(0px);
                                }
                            }
                        }
                        &:hover{
                            background-color: #d5d5d5;
                        }
                        .icon{
                            position: absolute;
                            right: 0;
                            top: 0;
                            background-color: #11304e;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
                .text{
                    font-size: 1.1rem;
                    text-align: justify;
                    line-height: 30px;
                }
            }
        }

        .footer{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: auto;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0 30px;
            button{
                width: 150px;
                height: 50px;
                background: transparent;
                border: 3px solid #d1d1d1;
                font-size: 12px;
                text-transform: uppercase;
                color: #333;
                &:first-of-type{
                    color: #fff;
                    border-color: #3cb27f;
                    background: #4bd197;
                    margin-right: 15px;
                }
                &.disabled{
                    cursor: initial;
                    opacity: 0.3;
                }
            }
        }

        @keyframes fadeIn{
            0%{
                opacity: 0;
                transform: translateY(150px);
            }
            100%{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
`;