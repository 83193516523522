import React from "react";
import { ContainerSponsor } from "../style";
import { ImgSponsors } from "../../assets/img/sponsors";

function SponsorList() {
  return (
    <ContainerSponsor>
      {ImgSponsors.map((sponsor, index:number) => (
        <a href={sponsor.link} target="_blank" rel="noreferrer"><img src={sponsor.photo} alt="" key={index} /></a>
      ))}
    </ContainerSponsor>
  );
}

export default SponsorList;
